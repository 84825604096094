import { render, staticRenderFns } from "./OrganisationOverview.vue?vue&type=template&id=15515ee2&scoped=true&"
import script from "./OrganisationOverview.vue?vue&type=script&lang=js&"
export * from "./OrganisationOverview.vue?vue&type=script&lang=js&"
import style0 from "./OrganisationOverview.vue?vue&type=style&index=0&id=15515ee2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15515ee2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBottomNavigation,VBottomSheet,VBreadcrumbs,VBtn,VFlex,VIcon,VLayout,VList,VListItem,VListItemTitle,VProgressCircular})
