<template>
	<S2SForm v-if="!loading || selectedOrganisation" :title="selectedOrganisation ? selectedOrganisation.name : 'Shop'" show-breadcrumbs="true">
		<template #breadcrumbs>
			<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		</template>
		<!-- TODO: Add error handling in case org cannot be found -->
		<router-view v-if="selectedOrganisation"></router-view>
		<v-flex xs1 v-if="!selectedOrganisation">
			<div>Shop not found</div>
			<v-btn class="text-xs-left" to="/organisations/shops">View Shops</v-btn>
		</v-flex>
		<v-bottom-navigation app v-if="selectedOrganisation">
			<v-bottom-sheet v-model="sheet">
				<template v-slot:activator="{ on }">
					<v-btn value="recent" v-on="on">
						<span>Recent Organisations</span>
						<v-icon>history</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item
						v-for="org in recentOrganisations"
						:key="org.id"
						:to="{ name: 'organisation-profile', params: { orgId: org.id } }"
						@click="sheet = false"
					>
						<v-list-item-title>{{ org.name }}</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-bottom-sheet>
		</v-bottom-navigation>
	</S2SForm>
	<v-layout v-else align-center justify-center column fill-height>
		<v-progress-circular :size="70" :width="7" color="accent" indeterminate></v-progress-circular>
	</v-layout>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
	name: "OrganisationOverview",

	props: {
		orgId: Number
	},

	computed: {
		selectedOrganisation: function() {
			return this.$store.state.organisations.selectedOrganisation;
		},
		loading: function() {
			return this.$store.state.organisations.loading;
		}
	},

	watch: {
		"$route.params.orgId": {
			immediate: true,
			handler(val) {
				this.$store.dispatch("organisations/fetchOrganisationById", val);
			}
		},
		$route: {
			immediate: true,
			handler(urlObj) {
				this.breadcrumbs = urlObj.meta.breadCrumbs.pattern;
			}
		}
	},

	data: function() {
		return {
			breadcrumbs: [],
			recentOrganisations: [],
			sheet: false
		};
	},

	mounted: function() {
		this.recentOrganisations = JSON.parse(localStorage.getItem("recent-organisations") || "[]");
	},

	methods: {
		fetchOrgDetails(val) {
			this.$store.dispatch("organisations/fetchOrganisationById", val);
		},
		onRouteChange(urlObj) {
			this.breadcrumbs = urlObj.meta.breadCrumbs.pattern;
		}
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navigation-btn {
	padding: 1.8em 1em 3.2em 1em;
	font-size: 16px;
	font-weight: 400;
	align-items: baseline;
}
</style>
